import React, { useCallback, useEffect, useMemo, useState } from "react";
import { notification, Input } from "antd";

import AccountLinks from "../AccountLinks";
import API from "../../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { getConsignmentType, languageLable } from "utilities/helpers";
import { IMAGE_BASE_URL, NoImg } from "utilities/constants";

export default function LibraryOrder() {
  const api = useMemo(() => new API(), []);

  const { app, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { selectedLanguageData } = app;
  const [libraryOrders, setLibraryOrders] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const locationId =
    auth &&
    auth.defaultAddress &&
    auth.defaultAddress.BusinessCustomerAddressMapping &&
    auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;

  const fetchLibraryOrder = useCallback(async () => {
    if (locationId) {
      const url = window.location.pathname.split("/");
      const groupId = url[3];

      try {
        const libraryData = await api.get(
          `GetLibraryOrders?locationId=${locationId}&groupId=${groupId ?? ""}`
        );

        if (libraryData?.Result?.length > 0) {
          setLibraryOrders(libraryData.Result);
          setSearchResults(libraryData.Result);
        }
      } catch (error) {
        console.error("fetchLibraryOrder -> error", error);
      }
    }
  }, [api, locationId]);

  useEffect(() => {
    fetchLibraryOrder();
  }, [fetchLibraryOrder]);

  const isUsed = (usedForCart, markAsUsed) => !usedForCart && !markAsUsed;

  const updateCart = () => {
    // Update the cart
    api.get("GetMyCart").then((response) => {
      if (response.StatusCode === 200 && response.Result) {
        dispatch({
          type: "FETCH_CART_DATA",
          cartData: response.Result,
        });
      }
    });
  };

  const handleUsedAction = async (product) => {
    if (isUsed(product.UsedForCart, product.MarkAsUsed)) {
      const consignmentType = getConsignmentType(
        product.Product.Listing.IsAuthorised
      );
      try {
        const libAddToCart = await api.get(
          `AddToCartLibrary?productSku=${product.ProductSKU}&locationId=${locationId}&serialNumber=${product.SerialNumber}&consignmentType=${consignmentType}`
        );

        if (libAddToCart.Result) {
          notification.success({
            message: "Success",
            description: languageLable(
              selectedLanguageData,
              "Item has been successfully added to cart."
            ),
          });

          updateCart();
        }
      } catch (error) {
        console.error("fetchLibraryOrder -> error", error);
      }
    } else {
      if (
        (product.UsedForCart === true,
        product.MarkAsUsed === false,
        product.Consumed === false)
      ) {
        const removeFromCart = await api.get(
          `RemoveFromCartLibrary?productSku=${product.ProductSKU}&locationId=${locationId}&serialNumber=${product.SerialNumber}`
        );

        if (removeFromCart.Result) {
          notification.success({
            message: "Success",
            description: languageLable(
              selectedLanguageData,
              "Item has been successfully removed from cart."
            ),
          });

          updateCart();
        }
      }
    }
  };

  const handleOnChange = (e) => {
    const search = e.target.value.toLowerCase();
    const searchMatch = [];
    libraryOrders.forEach((item) => {
      if (
        item.Product?.ProductName.toLowerCase().includes(search) ||
        item.SerialNumber.toLowerCase().includes(search)
      ) {
        searchMatch.push(item);
      }
    });

    setSearchResults(searchMatch);
  };

  return (
    <section className="ps-my-account ps-page--account">
      <div className="ps-container">
        <div className="row">
          <AccountLinks page="library-order" />
          <div className="col-lg-8">
            <div className="ps-page__content">
              <div className="ps-section--account-setting">
                <div class="container-fluid ">
                  <div className="ps-section__header">
                    <h3>
                      {languageLable(selectedLanguageData, "Library Order")}
                    </h3>
                  </div>
                  <div className="ps-section__content">
                    <div className="form-group">
                      <Input
                        placeholder="Search"
                        className="form-control"
                        onChange={handleOnChange}
                        allowClear={true}
                      />
                    </div>
                    {searchResults.map((item, index) => {
                      return (
                        <div
                          className="library-product-container"
                          key={`library-order-${index}`}
                        >
                          <div className="library-product">
                            <div className="image-container">
                              {item.Product?.PrimaryMediaId ? (
                                <img
                                  src={`${IMAGE_BASE_URL}${`/Media/GetMediaById?id=`}${
                                    item.Product?.PrimaryMediaId
                                  }`}
                                  alt={item.Product?.ProductName}
                                />
                              ) : (
                                <img
                                  src={NoImg}
                                  alt={item.Product?.ProductName}
                                />
                              )}
                            </div>
                            <div className="product-details">
                              <div className="title">
                                {item.Product?.ProductName}
                              </div>
                              {item.Product?.Attributes.map((attr) => (
                                <div className="description">
                                  {attr.attributeName}{" "}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="action-container">
                            <button
                              className={`ps-btn ${
                                isUsed(item.UsedForCart, item.MarkAsUsed)
                                  ? ""
                                  : "disabled"
                              }`}
                              onClick={() => handleUsedAction(item)}
                            >
                              {isUsed(item.UsedForCart, item.MarkAsUsed)
                                ? "Use Now"
                                : "Used"}
                            </button>
                            <div className="sr-number">
                              SR No : {item.SerialNumber}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
