import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { languageLable } from "utilities/helpers";
import { Table, Spin } from "antd";
import moment from "moment";
import API from "api/api";
const api = new API();
export default function BackupOrderTable() {
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);

  
  const appState = useSelector((state) => state);
  const { selectedLanguageData } = appState?.app;

  const fetchBackupOrder = useCallback(async () => {
    setLoading(true);

    try {
      const res = await api.get("GetBackupOrders");
      if (res.Result?.length > 0) {
        res.Result?.forEach((item, index) => {
          item.key = index;
        });

        setOrderData(res.Result);
      }
      setLoading(false);
    } catch (e) {
      console.log("Backup Order ~ fetchBackupOrder ~ e:", e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBackupOrder();
  }, [fetchBackupOrder]);

  const tableColumn = [
    {
      title: languageLable(selectedLanguageData, "Order Id"),
      dataIndex: "OrderRefNumber",
      key: "OrderRefNumber",
      render: (text, record) => (
        <Link to={`/account/order-detail/${record.Id}?consignmentType=Backup`} className="text-primary">
          {record.OrderRefNumber}
        </Link>
      ),
    },
    {
      title: languageLable(selectedLanguageData, "Delivered Date"),
      dataIndex: "DeliveredDate",
      key: "DeliveredDate",
      render: (text, record) => (
        <div>{moment(record.DocumentDate).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      title: languageLable(selectedLanguageData, "Type"),
      dataIndex: "ConsignmentType",
      key: "ConsignmentType",
      render: (text, record) => <>Backup</>,
    },
    {
      title: languageLable(selectedLanguageData, "Amount"),
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <div>{Number(record.TotalAmount) + Number(record.TotalTaxAmount)}</div>
      ),
    },
  ];

  return (
    <div className="backup-container">
      <Spin spinning={loading}>
        <Table columns={tableColumn} dataSource={orderData} />
      </Spin>
    </div>
  );
}
