import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import BiotechCalculator from "public/BiotechCalculator.png"
import { CALCULATOR_REDIRECT_TYPE, CALCULATOR_BASE_URL} from "utilities/constants"

function RedirectToCalculator(props) {

    const redirectToExternalApp = useCallback(() => {
        let url = CALCULATOR_BASE_URL
        const userTokenData = localStorage.getItem("userCalculatorData")
            ? JSON.parse(localStorage.getItem("userCalculatorData"))
            : null

        const authToken = localStorage.getItem("authToken")
            ? localStorage.getItem("authToken")
            : null

        const userData = localStorage.getItem("userData")
            ? JSON.parse(localStorage.getItem("userData"))
            : null

        if (userTokenData) {
            if (userTokenData.uid && userTokenData.calculator_token) {
                url += `?uid=${userTokenData.uid}&calculator_token=${userTokenData.calculator_token}&token=${authToken}&email=${userData.email}`
            } else {
                url += `?uid=&calculator_token=&token=${authToken}&email=${userData.email}`
            }
            switch (props.redirectType) {
                case CALCULATOR_REDIRECT_TYPE.HOME:
                    url += ''
                    break;
                case CALCULATOR_REDIRECT_TYPE.CATEGORY:
                    url += `&categoryId=${props.categoryName}`
                    break;
                case CALCULATOR_REDIRECT_TYPE.PRODUCT:
                    url += `&categoryId=${props.categoryName}`
                    break;
                default:
                    url += ""
                    break;
            }
        }
        window.open(url, "_blank")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        {
            props.isLoggedIn &&
            <div className={`text-center ${props.customClass} pointer`}>
                <Link
                    to={`#`}
                    onClick={() => redirectToExternalApp()}
                >
                    <img
                        src={BiotechCalculator}
                        alt={`Biotech Calculator`}
                        width={props.width || 120}
                        height={props.height || 120}
                    />
                </Link>
            </div>
        }
    </>
}

const mapStateToProps = (state) => {
    return state.auth;
};
export default connect(mapStateToProps)(withRouter(RedirectToCalculator));
