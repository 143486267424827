import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, notification } from "antd";
import API from "api/api";

const api = new API();

export default function AgreeModal({ closeAgreeModal, agreeModalOpen }) {
  const [secondCondition, setSecondCondition] = useState(false);
  const history = useHistory();

  const handleAgreeAction = () => {
    setSecondCondition(true);
  };

  const handleCloseModal = () => {
    history.push("/");
    closeAgreeModal();
  };

  const handleSecondAgreeAction = async () => {
    try {
      const resultData = await api.get(`ApproveLoyaltyProgram`);

      if (resultData?.Result) {
        notification.success({
          message: "Success",
          description:
            "Congratulations, you have been included in the Gloderm Loyalty program. The start date of your contract is today and its validity period is 1 year.",
        });
      }
    } catch (error) {
      console.error("fetchLibraryOrder -> error", error);
    } finally {
      history.push("/");
      closeAgreeModal();
    }
  };

  return (
    <>
      {agreeModalOpen && (
        <Modal
          title="Loyalty Program"
          open={agreeModalOpen}
          footer={null}
          onCancel={handleCloseModal}
          className="loyalty-agree-modal"
        >
          {secondCondition ? (
            <>
              <div className="button-container">
                <Button type="primary" onClick={handleSecondAgreeAction}>
                  Yes - I accept
                </Button>
              </div>
              <div className="button-container">
                <Button type="primary" onClick={handleCloseModal}>
                  No - I want to continue as a regular customer
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="title">
                Do you agree terms and conditions of Loyalty Program?
              </div>
              <div className="button-container">
                <Button type="primary" onClick={handleAgreeAction}>
                  Yes - I agree
                </Button>
              </div>
              <div className="button-container">
                <Button type="primary" onClick={handleCloseModal}>
                  No - I don't agree
                </Button>
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
}
