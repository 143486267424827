import React, { Component } from "react";
import { parseJwt } from "utilities/helpers";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import KycStrip from "../components/partials/account/KycStrip";
import HeaderDefault from "../components/shared/headers/HeaderDefault";
import FooterFullwidth from "../components/shared/footers/FooterFullwidth";
import HomeBanner from "../components/partials/homepage/home-default/HomeBanner";
import HeaderMobile from "../components/shared/headers/HeaderMobile";
import HomeDefaultTopCategories from "../components/partials/homepage/home-default/HomeDefaultTopCategories";
import SubscribePopup from "../components/shared/SubscribePopup";
import API from "api/api";

var api;
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribe: false,
    };
    api = new API();
  }

  async componentDidMount() {
    if (this.props.location && this.props.location.search) {

      const queryParams = new URLSearchParams(this.props.location.search);
      const email = queryParams.get('email')
      const uid = queryParams.get('uid')
      const sku = queryParams.get('sku')
      const calculator_token = queryParams.get('calculator_token')
      const authToken = queryParams.get('token')
      const isLoggedIn = this.props.auth?.isLoggedIn

      if (!isLoggedIn) {
        await localStorage.setItem("authToken", authToken);

        api.get("GetDefaults").then((response) => {
          if (response.StatusCode === 200 && response.Result) {
            localStorage.setItem("isLoggedIn", true);
            const tokenData = parseJwt(authToken);
            localStorage.setItem("userData", JSON.stringify(tokenData));

            // SET DATA TO REDUX
            this.props.dispatch({
              type: "LOGIN_USER",
              authToken: authToken,
              userData: tokenData,
            });

            const userCalculatorData = {
              uid,
              calculator_token
            };
            localStorage.setItem("userCalculatorData", JSON.stringify(userCalculatorData));

            this.addToCartBySKU(email, sku);
          } else {
            // Redirect to register
            this.props.history.push("/account/register");
          }
        });


      } else if (isLoggedIn) {
        this.addToCartBySKU(email, sku)
      }
    }
  }

  addToCartBySKU(email, sku) {
    const userData = this.props.auth?.userData;
    if (userData && email === userData.email && sku) {
      const locationId =
        this.props.auth &&
        this.props.auth.defaultAddress &&
        this.props.auth.defaultAddress.BusinessCustomerAddressMapping &&
        this.props.auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;

      api.get(`AddItemToCartBySKU/?locationId=${locationId}&productSku=${sku}`).then((response) => {
        if (response.StatusCode === 200) {
          this.props.history.push("/account/cart");
        }
      })
    }
  }

  render() {
    const { subscribe } = this.state;
    return (
      <div className="site-content">
        <HeaderDefault />
        <HeaderMobile />
        <SubscribePopup active={subscribe} />
        <main id="homepage-1">
          <HomeBanner />
          <div className="ps-container">
            <KycStrip />
          </div>
          <HomeDefaultTopCategories />
        </main>
        <FooterFullwidth />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(Index));
