import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Spin } from "antd";
import FooterDefault from "components/shared/footers/FooterDefault";
import HeaderDefault from "components/shared/headers/HeaderDefault";
import BreadCrumb from "components/elements/BreadCrumb";
import HeaderMobile from "components/shared/headers/HeaderMobile";
import NavigationList from "components/shared/navigation/NavigationList";
import AgreeModal from "./AgreeModal";
import Api from "api/api";

var api;
class LoyaltyGloderm extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      loading: true,
      pageData: null,
      agreeModalOpen: false,
    };
  }

  componentDidMount = () => {
    this.fetchLoyaltyGloderm();
  };

  fetchLoyaltyGloderm = () => {
    api.get(`GetStaticContent?pageType=LoyaltyProgram`).then((response) => {
      if (response.StatusCode === 200 && response.Result) {
        this.setState({
          pageData: response.Result,
          loading: false,
        });
      }
    });
  };

  handleCancel = () => {
    this.props.history.push(`/`);
  };

  closeAgreeModal = () => {
    this.setState({
      agreeModalOpen: false,
    });
  };

  openAgreeModal = () => {
    this.setState({
      agreeModalOpen: true,
    });
  }

  render() {
    const breadCrumb = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "Loyalty Gloderm",
      },
    ];
    return (
      <div className="loyalty-gloderm-page-container">
        <div className="site-content">
          <HeaderDefault />
          <HeaderMobile />
          <NavigationList />
          <div className="ps-page--shop">
            <BreadCrumb breacrumb={breadCrumb} layout="fullwidth" />
            <div className="ps-container">
              <Spin spinning={this.state.loading}>
                <div
                  className="loyalty-gloderm-page"
                  dangerouslySetInnerHTML={{
                    __html: this.state.pageData?.Content,
                  }}
                />
                <div className="button-container">
                  <Button type="primary" onClick={this.openAgreeModal}> Yes - I agree </Button>
                  <Button type="primary" onClick={this.handleCancel}>
                    {" "}
                    No - I don't agree{" "}
                  </Button>
                </div>
              </Spin>
            </div>
          </div>
          <AgreeModal
            agreeModalOpen={this.state.agreeModalOpen}
            closeAgreeModal={this.closeAgreeModal}
          />
          <FooterDefault />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(withRouter(LoyaltyGloderm));
