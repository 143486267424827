import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const app = document.getElementById("root");

// create a root
const root = createRoot(app);

//render app to root
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
