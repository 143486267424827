import React, { Component } from "react";
import { Menu } from "antd";
import categories from "../../../public/static/data/static-categories.json";

class PanelCategories extends Component {
  rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  state = {
    openKeys: ["sub1"],
  };
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    return (
      <Menu
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
      >
        {categories.map((category) => (
          <Menu.Item key={category.id}>
            <a href={`/shop?category=${category.slug}`}>{category.name}</a>
          </Menu.Item>
        ))}
      </Menu>
    );
  }
}

export default PanelCategories;
