import React, { Component } from "react";
import { connect } from "react-redux";
import MiniCart from "./MiniCart";
import AccountQuickLinks from "./AccountQuickLinks";
import RedirectToCalculator from "components/partials/commons/RedirectToCalculator";

class HeaderActions extends Component {
  render() {
    const { auth } = this.props;
    return (
      <div className="header__actions">
        {this.props.auth && this.props.auth.isLoggedIn && (
          <React.Fragment>
            <RedirectToCalculator width="45" height="45" />
            <MiniCart />
          </React.Fragment>
        )}
        {auth.isLoggedIn && Boolean(auth.isLoggedIn) === true ? (
          <AccountQuickLinks isLoggedIn={true} />
        ) : (
          <AccountQuickLinks isLoggedIn={false} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(HeaderActions);
