import React from "react";
import {  useSelector } from "react-redux";
import { languageLable } from "utilities/helpers";
import AccountLinks from '../AccountLinks';
import BackupOrderTable from "./BackupOrderTable"

export default function DueInvoice() {

    const appState = useSelector(state => state)
    const { selectedLanguageData } = appState.app;

    return (
        <section className="ps-my-account ps-page--account">
            <div className="ps-container">
                <div className="row">
                    <AccountLinks page="backup-order" />
                    <div className="col-lg-8">
                        <div className="ps-page__content">
                            <div className="ps-section--account-setting">
                                <div className="ps-section__header">
                                    <h3>{languageLable(selectedLanguageData, "Backup History")} </h3>
                                </div>
                                <div className="ps-section__content">
                                    <BackupOrderTable />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
