import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

export default function GlodermModal() {
  const history = useHistory();
  const appState = useSelector((state) => state);
  const { appDefaultSetting } = appState?.auth;
  const { loyaltyGolderm } = appState?.app;
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      appDefaultSetting?.IsGloderCustomer &&
      !appDefaultSetting?.IsLoyaltyProgram &&
      !loyaltyGolderm.modalAlreadyOpen &&
      location.pathname === "/"
    ) {
      dispatch({
        type: "LOYALTY_GLODERM_MODAL",
        loyaltyGolderm: {
          isOpenModal: true, // TO OPEN
          modalAlreadyOpen: true, // ALREADY OPEN
          withoutLoyaltyGolderm: false, // USER DO NOT WANT TO CONTINUE WITH LOYALTY
        },
      });
    }
  }, [
    appDefaultSetting?.IsGloderCustomer,
    appDefaultSetting?.IsLoyaltyProgram,
    dispatch,
    location?.pathname,
    loyaltyGolderm.modalAlreadyOpen,
  ]);

  const handleCloseModal = () => {
    dispatch({
      type: "LOYALTY_GLODERM_MODAL",
      loyaltyGolderm: {
        isOpenModal: false, // HIDE MODAL
        modalAlreadyOpen: true, // ALREADY OPEN
        withoutLoyaltyGolderm: true, // USER DO NOT WANT TO CONTINUE WITH LOYALTY
      },
    });
  };

  const handleRedirectToLoyaltyTerm = () => {
    handleCloseModal();
    history.push("/loyalty-gloderm");
  };

  return (
    <>
      {loyaltyGolderm?.isOpenModal && (
        <Modal
          title="Loyalty Program"
          open={loyaltyGolderm?.isOpenModal}
          footer={null}
          onCancel={handleCloseModal}
          className="loyalty-container"
        >
          <div className="loyalty-link" onClick={handleRedirectToLoyaltyTerm}>
            Become a member of the Loyalty
          </div>
          <div className="loyalty-link" onClick={handleCloseModal}>
            Continuing without Loyalty program
          </div>
        </Modal>
      )}
    </>
  );
}
